<template>
  <div class="text-center">
    <h1>{{ $t('app_name') }}</h1>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },
  mounted() {
    if (!this.currentUser) {
      this.$store.dispatch('refreshUser');
    }
  },
};
</script>
